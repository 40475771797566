.container{
    background-color: var(--secondary-color);
    width: 100%;
    height: 100%;
    padding: var(--spacing);
}

.titleRow{
    max-width: 950px;
}

.title{
    font-size: 50px;
    padding-bottom: 15px;
}

.content{
    width: 100%;
    gap: 30px;
    max-width: 970px;
}

.item{
    background-color: var(--primary-text);
    width: 300px;
    height: 300px;
    border-radius: 20px;
    gap: 10px;
    padding: 30px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.logos{
    height: 50px;
    width: 50px;
}

/* sm formatting */
@media screen and (min-width: 300px) and (max-width: 576px) {
    .title{
        font-size: 35px;
    }
}

/* md formatting */
@media screen and (min-width: 577px) and (max-width: 768px) {
    .title{
        font-size: 40px;
    }
}

/* lg formatting */
@media screen and (min-width: 769px) and (max-width: 992px) {
    .title{
        font-size: 44px;
    }
}

/* xl formatting */
@media screen and (min-width: 993px) and (max-width: 1200px) {
    .title{
        font-size: 48px;
    }
}

/* xxl formatting */
@media screen and (min-width: 1201px) {
    .title{
        font-size: 52px;
    }
}