:root{
    --primary-color: #000000;
    --secondary-color: #BEBEBE;
    --primary-text: #ffffff;
    --secondary-text: #000000;
    --secondary-subtext: #656565;
    --impact-color: #543536;

    --spacing:20px;

}

h1 {
    font-weight: 900;
}

.archivo-100 {
  font-family: "Archivo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.archivo-200 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-300 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-400 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-500 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-600 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-700 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-800 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }

  .archivo-900 {
    font-family: "Archivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }


body {
    font-family: 'Archivo', sans-serif;
    overflow-x: hidden;
  }

.are_you_titles{
    font-size: 46px;
    text-align: center;
}

.are_you_captions {
    text-align: center;
    }

.swiper-button-prev, .swiper-button-next {
    color: var(--secondary-subtext);
}
.swiper-pagination-bullet{
    background-color: var(--secondary-subtext);
}

/* sm formatting */
@media screen and (min-width: 320px) and (max-width: 576px) {
    :root{
        --spacing: 30px;
    }

    .hero-picture{
        height: 350px;
        width: 250px;
        transform: rotate(0deg);
    }
    h1{
        font-size: 70px;
        line-height: 70px;
        z-index: 1;
    }

    .swiper-wraper {
        height: auto !important;
    }

    .are_you_titles{
        font-size: 36px;
        text-align: center;
    }

    .are_you_captions{
        font-size: 22px;
        text-align: center;
    }
}

/* md formatting */
@media screen and (min-width: 577px) and (max-width: 768px) {
    :root{
        --spacing: 40px;
    }
    .hero-picture{
        height: 400px;
        width: 300px;
        transform: rotate(0deg);
    }
    h1{
        font-size: 70px;
        line-height: 70px;
        z-index: 1;
    }
}

/* width: 595.8
height: 662 */

/* lg formatting */
@media screen and (min-width: 769px) and (max-width: 992px) {
    :root{
        --spacing: 50px;
    }
    .hero-picture{
        height: 550px;
        width: 400px;
        transform: rotate(0deg);
    }
    h1{
        font-size: 140px;
        line-height: 140px;
        z-index: 1;
    }
}

/* xl formatting */
@media screen and (min-width: 993px) and (max-width: 1200px) {
    :root{
        --spacing: 60px;
    }
    .hero-picture{
        height: 600px;
        width: 450px;
        transform: rotate(0deg);
    }
    h1{
        font-size: 170px;
        line-height: 170px;
        z-index: 1;
    }
}

/* xxl formatting */
@media screen and (min-width: 1201px) {
    :root{
        --spacing: 70px;
    }
    .hero-picture{
        height: 650px;
        width: 500px;
        margin-bottom: 100px;
        transform: rotate(0deg);
    }
    h1{
        font-size: 200px;
        line-height: 200px;
        z-index: 1;
    }
}