
.container{
    height: auto;
    background-color: var(--secondary-color);
    padding: var(--spacing);
}

.content{
    max-width: 1200px;
    column-gap: 40px;
}

.title{
    min-width: 200px;
}

.titleText{
    font-size: 20px;
    color: var(--secondary-text);
    font-weight: 600;
}

.titleImpact{
    background-color: var(--primary-color);
    width: 80%;
    max-width: 700px;
}

.titleImpactText{
    color: var(--primary-text);
    font-weight: 600;
}


.Typewriter {
    font-size: 90px !important;
}

.iphone {
    height: 300px;
    width: 280px;
}

/* sm formatting */
@media screen and (min-width: 300px) and (max-width: 576px) {
    .titleText{
        font-size: 32px;
    }
    .titleImpact{
        min-width: 275px;
    }
    .titleImpactText{
        font-size: 35px;
    }
    .iphone {
        height: 300px;
        width: 280px;
    }
}

/* md formatting */
@media screen and (min-width: 577px) and (max-width: 768px) {
    .titleText{
        font-size: 54px;
    }
    .titleImpactText{
        font-size: 54px;
    }
    .iphone {
        height: 400px;
        width: 500px;
    }
}

/* lg formatting */
@media screen and (min-width: 769px) and (max-width: 992px) {
    .titleText{
        font-size: 54px;
    }
    .titleImpact{
        min-width: 400px;
    }
    .titleImpactText{
        font-size: 54px;
    }
    .iphone {
        height: 400px;
        width: 500px;
    }
}

/* xl formatting */
@media screen and (min-width: 993px) and (max-width: 1200px) {
    .titleText{
        font-size: 54px;
    }
    .titleImpact{
        min-width: 400px;
    }
    .titleImpactText{
        font-size: 54px;
    }
    .iphone {
        height: 400px;
        width: 500px;
    }
}

/* xxl formatting */
@media screen and (min-width: 1201px) {
    .titleText{
        font-size: 64px;
    }
    .titleImpact{
        min-width: 400px;
    }
    .titleImpactText{
        font-size: 64px;
    }
    .iphone {
        height: 400px;
        width: 500px;
    }
}