.container{
    height: 500px;
    min-width: 200px;
    background-color: var(--secondary-color);
}

.contactLabel{
    width: 100%;
    font-family:var(--primary-font);
    font-size: 22px;
    font-weight: 900
}

.contactDiv{
    width:100%;
}

.contactFields{
    all: unset;
    width: 100%;
    border-radius: 5px;
    color: var(--primary-text);
    background-color: #4D4D4d;
    padding: 10px 0;
    border: 'none';
}

.contactFieldsHelp{
    height: 75px;
    max-height: 400px;
}

.contactSubmitButton{
    height: 50px;
    width: 75%;
    border-radius: 5px;
    margin: 20px 0;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-family: var(--primary-font);
    font-weight: 900;
    transition: all 0.3s ease-in-out;
}

.contactSubmitButton:hover{
    background-color: var(--primary-color);
    color: var(--primary-text);
}

.successMessage{
    width: auto;
    background-color: green;
}